import cloudServiceImg1 from "../../assets/images/cloud-service/paas.webp";
import cloudServiceImg2 from "../../assets/images/cloud-service/saas.webp";
import cloudServiceImg3 from "../../assets/images/cloud-service/cloud-storage.webp";
import cloudServiceImg4 from "../../assets/images/cloud-service/data-analytics.webp";
import cloudServiceImg5 from "../../assets/images/cloud-service/secruity-identify-management.webp";
import cloudServiceImg6 from "../../assets/images/cloud-service/cloud-migration.webp";
import cloudServiceImg7 from "../../assets/images/cloud-service/cloud-contact-center.webp";
import cloudServiceImg8 from "../../assets/images/cloud-service/ui-ux.webp";

export const CloudService = [
  {
    id: "section1",
    title: "Platform as a Service (PaaS)",
    text: "VISTA is a Platform as a Service (PaaS) provider that offers a variety of solutions for companies that want to improve their operations. ",
    path: "/",
    img: cloudServiceImg1,
    alt: "Platform as a Service (PaaS)",
  },
  {
    id: "section2",
    title: "Software as a Service (SaaS)",
    text: "VISTA is also Software as a Service (SaaS) provider that offers various services to businesses. ",
    path: "/",
    img: cloudServiceImg2,
    alt: "Software as a Service (SaaS)",
  },
  {
    id: "section3",
    title: "Cloud Storage and Content Delivery",
    text: "Cloud storage allows users to store their files and data on remote servers accessed through the internet. ",
    path: "/",
    img: cloudServiceImg3,
    alt: "Cloud Storage and Content Delivery",
  },
  {
    id: "section4",
    title: "Data Analytics",
    text: "VISTA is a reputable company specializing in the provision of cutting-edge data analytics services. ",
    path: "/",
    img: cloudServiceImg4,
    alt: "Data Analytics",
  },

  {
    id: "section5",
    title: "Security and Identity Management",
    text: "Learn how our robust AI Data Solutions can help advance your generative AI initiatives.",
    path: "/",
    img: cloudServiceImg5,
    alt: "Security and Identity Management",
  },
  {
    id: "section6",
    title: "Cloud Migration and Management",
    text: "VISTA's cloud migration and management service is not just beneficial but rather crucial for companies seeking to update and streamline their business practices. ",
    path: "/",
    img: cloudServiceImg6,
    alt: "Cloud Migration and Management",
  },
  {
    id: "section7",
    title: "Cloud Contact Center",
    text: "VISTA  a cutting-edge cloud-based contact center solution  revolutionizes the way businesses handle customer interactions. ",
    path: "/",
    img: cloudServiceImg7,
    alt: "Cloud Contact Center",
  },
  {
    id: "section8",
    title: "UX/UI Design",
    text: "VISTA is a renowned company known for its expertise in providing top-notch UX/UI design services. ",
    path: "/",
    img: cloudServiceImg8,
    alt: "UX/UI Design",
  },
];
